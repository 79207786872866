import React from 'react'
import './services.css';
import {Card, CardGroup, Row, Col, Button, Nav} from 'react-bootstrap';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Pdf from "../documents/formulaire_d_emploi.pdf";
import photo3 from "../assets/photo3.jpg";
import photo4 from "../assets/photo4.png";
import photo5 from "../assets/photo5.jpg";
import icon1 from "../assets/nurse.png";
import icon2 from "../assets/medical.png";
import icon3 from "../assets/medical1.png";
import icon4 from "../assets/nursing-home.png";
import icon5 from "../assets/receptionist.png";
import icon6 from "../assets/training.png";
import icon7 from "../assets/advisor.png";
import icon8 from "../assets/secretary.png";

const Services = () => {
  return (
    <>
        <section>
            <div className='text-center py-5'>
                <h1>Nos services</h1>
            </div>
            <div>
                <Row className='py-5'>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide left>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon1} className='icon' alt='icon'></img>
                                <span className='text'>Infirmiers, Infirmières</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide left>   
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon2} className='icon' alt='icon'></img>
                                <span className='text'>Infirmiers, Infirmières auxiliaires</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide top>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon3} className='icon' alt='icon'></img>
                                <span className='text'>Inhalothérapeutes</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide right>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon4} className='icon' alt='icon'></img>
                                <span className='text'>Préposés aux bénéficiaires</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide right>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon5} className='icon' alt='icon'></img>
                                <span className='text'>Secrétaires médicales</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide right>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon6} className='icon' alt='icon'></img>
                                <span className='text'>Éducateurs (trices)</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide right>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon7} className='icon' alt='icon'></img>
                                <span className='text'>Agents (es) d'intervention</span>
                        </div>
                        </Slide>
                    </Col>
                    <Col style={{ width: 300, height: 100 }} md={2} sm={12}>
                        <Slide right>
                        <div className="rectangle d-flex align-items-center">
                            <div className="inner-rounded"></div>
                                <img src={icon8} className='icon' alt='icon'></img>
                                <span className='text'>Agents (es) en réadaptation</span>
                        </div>
                        </Slide>
                    </Col>
                </Row>
                <Row>
                    <Zoom bottom>
                        <h4 className='py-5 text-center hh'>
                            Notre équipe est composée de ressources triées sur le volet afin
                            de satisfaire aux plus hauts standards de l'industrie. <br />
                            Parmi les ressources disponibles avec notre agence afin de
                            supporter adéquatement vos équipes déjà en place.
                        </h4>
                    </Zoom>
                </Row>           
                <Row>               
                        <CardGroup className='text-center'>
                                <Card>
                                    <Card.Img variant="top" src={photo3} style={{height:432}}className='img'/>
                                    <Card.Body>
                                    <Card.Title>Personnel de qualité</Card.Title>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src={photo4} className='img'/>
                                    <Card.Body>
                                    <Card.Title>Professionnalisme</Card.Title>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src={photo5} style={{height:432}} className='img'/>
                                    <Card.Body>
                                    <Card.Title>Tranquilité d'esprit</Card.Title>
                                    </Card.Body>
                                </Card>
                            </CardGroup>   
                    </Row> 
            </div>
            </section>
            <section>
            <div>
                <div className='text-center py-5'>
                    <h1>Nos emplois</h1>
                </div>
                <Row>
                    <Col>
                        
                            <Card border="dark" style={{ width: '18rem' }}>
                                <Card.Header>Débutez une carrière chez Santé Jiskobou</Card.Header>
                                <Card.Body>
                                <Card.Title>Postulez dès maintenant</Card.Title>
                                <Card.Text>
                                    Vous pouvez remplir notre formulaire d'emploi et nous le renvoyer
                                    par courriel à: 
                                    <Nav.Link href="mailto:info@santejiskobou.com">info@santejiskobou.com</Nav.Link>
                                    <br/>
                                    <p>
                                        <a href="tel:+1-438-337-3037"><i class="fa-solid fa-phone-volume"> (438)337-3037</i></a>
                                    </p>
                                    <Nav.Link href={Pdf} target="_blank" rel="noreferrer"><i class="fa-regular fa-file"></i> Document à télécharger</Nav.Link>
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        
                    </Col>
                    <Col>
                        
                            <Card border="dark" style={{ width: '18rem' }}>
                                <Card.Header className='text-center'>Vous êtes déjà un membre</Card.Header>
                                <Card.Body>
                                <Card.Text className='text-center'>
                                    <Button variant="Light"><Nav.Link href="https://jiskobou-ws.herokuapp.com" target="_blank" rel="noreferrer">Cliquez ici</Nav.Link></Button>            
                                </Card.Text>
                                </Card.Body>
                            </Card>
                    
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}

export default Services